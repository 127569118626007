<!--
* @Description: 
* @Author: sunchunchun
* @CreateDate: 2024/07/17 16:20:36
* @FileName: comPhoneListLogin.vue
!-->
<template>
    <div class="company-box">
      <div class="cardForm" style="margin-top:-20px">
        <div style="display:flex">
                    <img style="width:29px;height:29px;margin-top:13px;margin-left:15px" src="../../../../assets/images/shiming-icon.png">
                    <span style="margin-top:19px;font-size:16px;margin-left:9px">实名认证</span>
        </div>
        <div style="text-align: left;font-size: 15px;padding-top:13px;padding-left:5%">
            <span style="color: black">2步</span><span style="color: black">即可完成企业手机号修改</span>
        </div>
        <!-- 企业重新认证 -->
        <div class="imgs">
                <div class="postions" style="margin-top:-6px">
                    <div v-if="comEditFace == 1" @click="onFace">
                        <img :src="faceActive" alt="">
                        <span class="abslot number active" style="color:#BEA588">1</span>
                        <p class="abslot title active" style="color:#fff">人脸认证</p>
                        <p class="abslot cont active" style="color:#fff">需要您本人完成人脸认证</p>
                    </div>
                    <div v-if="comEditFace == 2">
                        <img :src="success" alt="">
                        <p class="abslot title success" style="color:#000">人脸认证</p>
                        <p class="abslot cont err" style="color:#000">需要您本人完成人脸认证</p>
                    </div>
                </div>
                <div class="postions">
                    <div v-if="comEditLice == 0">
                        <img :src="license" alt="" >
                        <span class="abslot number err" style="color:#BEA588">2</span>
                        <p class="abslot title err" style="color:#fff">上传证照</p>
                        <p class="abslot cont err" style="color:#fff">请上传营业执照</p>
                    </div>
                    <div v-if="comEditLice == 1" @click="onLicens">
                        <img :src="licenseActive" alt="" >
                        <span class="abslot number active" style="color:#BEA588">2</span>
                        <p class="abslot title active" style="color:#fff">上传证照</p>
                        <p class="abslot cont active" style="color:#fff">请上传营业执照</p>
                    </div>
                    <div v-if="comEditLice == 2">
                        <img :src="success" alt="">
                        <p class="abslot title success" style="color:#000">上传证照</p>
                        <p class="abslot cont err" style="color:#000">请上传营业执照</p>
                    </div>
                </div>
            </div>
      </div>
    </div>
</template>

<script>
import storage from 'store'
export default {
    data () {
        return {
            comEditFace: 1, // 人脸识别 0、未开始 1、进行中 2、已完成
            comEditLice: 0, // 身份证认证 0、未开始 1、进行中 2、已完成
            
            success: require('@/assets/images/face-complete.png'),
            license: require('@/assets/images/license.png'),
            licenseActive: require('@/assets/images/lience-act.png'),
            card: require('@/assets/images/idcard-noact.png'),
            cardActive: require('@/assets/images/idcard-act.png'),
            face: require('@/assets/images/face-notact.png'),
            faceActive: require('@/assets/images/face-act.png'),

        }
    },
    created() {
        let str = JSON.stringify(this.$route.params)
        if (str != '{}') {
            let params = this.$route.params
            // 接收路由 并存储vuex
            this.comEditFace = params.comFace
            this.comEditLice = params.comLice
        }
        // 修改完成后 进行更新接口并返回
        this.$nextTick(_ => {
            if (this.comEditLice == 2) {
                this.$router.push({name: 'editPhoneLogin', query: {phone: storage.get('phoneNoLoginPhone')}})
            }
        })
    },
    methods: {
        // 人脸识别
        onFace() {
            // 存储 区分个人和企业
            storage.set('phoneNoLogins', 2)
            this.$router.push({name: "phoneFaceLogin", query: { phone: this.$route.query.phone, userId: this.$route.query.userId }})
        },
        // 跳转营业执照的识别
        onLicens() {
            this.$router.push({name: "conLicenseUplod", query: { phone: storage.get('phoneNoLoginPhone'), userId: storage.get('phoneNoLoginUserId') }})
        },
    }
}
</script>

<style lang='scss' scoped>
.company-box{
    height: 100vh;
    padding: 130px 40px 20px 40px;
    .imgs{
        width: 100%;
        text-align: center;
        margin-top: 5px;
        .postions{
            color: #fff;
            position: relative;
            .title{
                font-weight: bold;
                left: 190px;
                top: 120px;
            }
            .cont{
                left: 190px;
                top: 180px;
            }
            .abslot{
                margin: -47px;
                padding: 44px;
                padding: 0;
                font-size: 25px;
                position: absolute;
            }
            .number{
                top: 153px;
                left: 126px;
                font-size: 26px;
            }
            img{
                width: 650px;
                height: 210px;
                margin-top: 20px;
            }
        }
    }
}
</style>